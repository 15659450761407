<template>
    <div>
        <div v-if="$route.path === '/wuyiIndex'" class="page" ref="page" >
        <!-- <div v-wechat-title="$route.meta.title"></div> -->
        <div class="Up">
            <div class="Main">
                
                <img src="../static/image/indeximg/-s-Group-21@2x.png" class="big_img" ref="big_img" v-if="end" @click="price_alert">
                <div class="big_end_info" v-else>
                    <img src="../static/image/indeximg/-s-Group-21@2x.png" class="big_img_end">
                    <div class="big_end_text">已结束</div>
                </div>
                
            </div>
            <div class="middle">
                <div class="prize_chance" ref="prize_chance">{{prize_chance}}次收获机会</div>
                <div class="btn_group" >
                    <img src="../static/image/indeximg/Group 28.png" class="myAward" 
                        @click="goMyAward"
                    >
                    <div class="button" v-if="end" @click="price_alert">
                        <img src="../static/image/indeximg/Layer 16.png" class="openBtn">
                        <div class="openBtnText" >收获麦子</div>
                    </div>
                    <div class="button" v-else>
                        <img src="../static/image/indeximg/Group 31.png" class="end_btn">
                    </div>
                    <img src="../static/image/indeximg/Group 26.png" class="getChange" is-link @click="showgetChangeFloat">
                    <van-popup v-model="show_getChange" position="bottom" round lock-scroll @close='close_popue'>
                        <div class="float">
                            <div class="float_main">
                                <div class="exchange_wrap">
                                    <!-- 初始化兑换界面 -->
                                    <div class="score" v-show="score">
                                        <img src="../static/image/float/Vector Smart Object copy.png" class="score_icon">
                                        <div class="my_score">我的积分:{{myScore}}</div>
                                        <div class="exchange_time">今日剩余兑换{{scoreTime}}次</div>
                                        <div class="exchange_btn" @click="exchange_btn(1)">888积分1次</div>
                                    </div>
                                    <div class="meimei" v-show="meimei">
                                        <img src="../static/image/float/meimei.png" class="meimei_icon">
                                        <div class="my_meimei">我的美枚:{{myMeimei}}枚</div>
                                        <div class="exchange_time">今日剩余兑换{{meimeiTime}}次</div>
                                        <div class="exchange_btn" @click="exchange_btn(2)">8美枚1次</div>
                                    </div>
                                    <!-- 我要兑换---积分 -->
                                    <div class="exchange_score" v-show="exchange_score">
                                        <img src="../static/image/float/Vector Smart Object copy.png" class="exchange_score_icon">
                                        <div class="today_exchanged_score">今日已兑换：{{today_exchanged_time_score}}次</div>
                                        <div class="today_residue_score">今日剩余可兑换：{{scoreTime}}次</div>
                                        <div class="exchange_sure_score" @click="to_change(1)">我要兑换</div>
                                    </div>
                                    <!-- 我要兑换---美枚 -->
                                    <div class="exchange_meimei" v-show="exchange_meimei">
                                        <img src="../static/image/float/meimei.png" class="exchange_meimei_icon">
                                        <div class="today_exchanged_meimei">今日已兑换：{{today_exchanged_time_meimei}}次</div>
                                        <div class="today_residue_meimei">今日剩余可兑换：{{meimeiTime}}次</div>
                                        <div class="exchange_sure_meimei" @click="to_change(2)">我要兑换</div>
                                    </div>
                                    <!-- 兑换成功  积分 -->
                                    <div class="exchange_success_score" v-show="exchange_success_score">
                                        <img src="../static/image/float/Vector Smart Object copy.png" class="exchange_score_icon">
                                        <div class="success_exchanged_one">成功兑换1次收获机会</div>
                                        <div class="success_exchanged_desc">收获机会仅当天有效</div>
                                        <div class="know" @click="goFloatIndex">我知道了</div>
                                    </div>
                                    <!-- 兑换成功  美枚 -->
                                    <div class="exchange_success_meimei" v-show="exchange_success_meimei">
                                        <img src="../static/image/float/meimei.png" class="exchange_meimei_icon">
                                        <div class="success_exchanged_one">成功兑换1次收获机会</div>
                                        <div class="success_exchanged_desc">收获机会仅当天有效</div>
                                        <div class="know" @click="goFloatIndex">我知道了</div>
                                    </div>
                                    <!-- 积分不足 -->
                                    <div class="score_no" v-show="score_no">
                                        <img src="../static/image/float/Vector Smart Object copy.png" class="exchange_score_icon">
                                        <div class="score_no_desc">积分不足，无法兑换</div>
                                        <div class="know" @click="close">我知道了</div>
                                    </div>
                                    <!-- 美枚不足 -->
                                    <div class="meimei_no" v-show="meimei_no">
                                        <img src="../static/image/float/meimei.png" class="exchange_meimei_icon">
                                        <div class="meimei_no_desc">美枚不足，无法兑换</div>
                                        <div class="know" @click="close">我知道了</div>
                                    </div>
                                    <!-- 积分兑换次数已用完 -->
                                    <div class="score_no_time" v-show="score_no_time">
                                        <img src="../static/image/float/Vector Smart Object copy.png" class="exchange_score_icon">
                                        <div class="score_no_desc">今日兑换次数已用完，明天再来吧</div>
                                        <div class="know" @click="close">我知道了</div>
                                    </div>
                                    <!-- 美枚兑换次数已用完 -->
                                    <div class="meimei_no_time" v-show="meimei_no_time">
                                        <img src="../static/image/float/meimei.png" class="exchange_meimei_icon">
                                        <div class="meimei_no_desc">今日兑换次数已用完，明天再来吧</div>
                                        <div class="know" @click="close">我知道了</div>
                                    </div>
                                </div>
                                <div class="exchange_title">完成任务获得机会</div>
                                <div class="mession_group">
                                    <div class="issue">
                                        <img src="../static/image/float/issue.png" class="issue_icon">
                                        <div class="issue_info">
                                            <div class="issue_title">发布视频，获得收获机会</div>
                                            <div class="issue_desc1">完成APP登录，</div>
                                            <div class="issue_desc1">获得1次收获机会，每日1次</div>
                                        </div>
                                        <div class="finished" v-if="issue_isFinished" @click="close">已完成</div>
                                        <div class="issue_btn" v-else @click="makeVideo()">去发布</div>
                                    </div>
                                    <div class="share">
                                        <img src="../static/image/float/share.png" class="share_icon">
                                        <div class="share_info">
                                            <div class="share_title">分享视频，获得收获机会</div>
                                            <div class="share_desc1">APP内分享视频，</div>
                                            <div class="share_desc1">获得1次收获机会，每日1次</div>
                                        </div>
                                        <div class="finished" v-if="share_isFinished" @click="close">已完成</div>
                                        <div class="share_btn" v-else @click="close_web()">去分享</div>
                                    </div>
                                    <div class="invite">
                                        <img src="../static/image/float/invite.png" class="invite_icon">
                                        <div class="invite_info">
                                            <div class="invite_title">邀请好友，获得收获机会</div>
                                            <div class="invite_desc1">邀请新用户注册APP，</div>
                                            <div class="invite_desc1">获得1次收获机会，每日1次</div>
                                        </div>
                                        <div class="finished" v-if="invite_isFinished" @click="close">已完成</div>
                                        <div class="invite_btn" v-else 
                                        @click="$router.push(`/wuyiInvite?userId=${userId}&token=${token}`)">去邀请</div>
                                    </div>
                                </div>
                            </div>
                            <img src="../static/image/float/validityDesc.png" class="validityDesc">
                            <img src="../static/image/float/exchangeTitle@2x.png" class="exchangeTitle">
                            <img src="../static/image/float/close.png" class="close" @click="close">
                        </div>
                    </van-popup>

                </div>
            </div>
        </div>
        <div class="rule" @click="$router.push('/wuyiRule')"></div>
        <div class="Down"></div>
        <!-- 提示弹窗 -->
        <div class="alert_master" v-show="alert_view" @click="close(2)">
            <div class="alert_main">
                <!-- 红包未开始 -->
                <div class="red_nostart" v-show="red_nostart">
                    <div class="line"></div>
                    <div class="alert_title">抢跨年红包</div>
                    <div class="red_time">12月31日 23:50-24:00</div>
                    <div class="red_desc">来云美摄抢1000个跨年红包瓜分新年好运</div>
                    <div class="red_condition">每个用户仅可领取1次</div>
                    <div class="red_know" @click="close">朕明白了</div>
                </div>
                <!-- 红包已结束 -->
                <div class="red_finished" v-show="red_finished">
                    <div class="line"></div>
                    <div class="alert_title">抢跨年红包</div>
                    <div class="red_finishedTime">12月31日 23:50-24:00</div>
                    <div class="red_desc">活动已结束</div>
                    <div class="red_know">朕明白了</div>
                </div>
                <!-- 红包已抢完 -->
                <div class="red_rod" v-show="red_rod">
                    <div class="line"></div>
                    <div class="alert_title">抢跨年红包</div>
                    <div class="red_finishedTime">12月31日 23:50-24:00</div>
                    <div class="red_desc">红包已抢完</div>
                    <div class="red_know">朕明白了</div>
                </div>
                <!-- 福蛋未开始 -->
                <div class="lucky_bag" v-show="lucky_bag">
                    <div class="line"></div>
                    <div class="alert_title">砸新年福蛋</div>
                    <div class="lucky_time">1月1日-1月20日</div>
                    <div class="lucky_desc">来云美摄砸新年福蛋砸出福气满满</div>
                    <div class="lucky_know">朕明白了</div>
                </div>
                <!-- 袜子已结束 -->
                <div class="stockings_finished" v-show="stockings_finished">
                    <div class="line"></div>
                    <div class="alert_title">拆圣诞袜子</div>
                    <div class="red_finishedTime">12月14日-12月31日</div>
                    <div class="red_desc">活动已结束</div>
                    <div class="red_know">朕明白了</div>
                </div>
                
            </div>
            <img src="../static/image/alertimg/close-circle.png" class="close_alert" @click="close">
        </div>
        <!-- 奖品弹窗 -->
        <div class="award_master" v-show="award_master" @click="close(1)">
            <div class="award_main">
                <div class="price_title">恭喜你获得</div>
                <div class="price_name">{{price_name}}</div>
                <div class="price">{{price}}</div>
                <div>
                    <div class="goMoney" v-if="is_redBag" @click="goMoney">去提现</div>
                    <div class="see_price" v-else
                        @click="$router.push(`/wuyiMyAward?userId=${userId}&token=${token}`)">
                        去看看
                    </div>
                </div>
                <div class="share_lucky" @click="shareFloat">分享好运</div>
            </div>
            <img src="../static/image/alertimg/close-circle.png" class="award_close" @click="close">
        </div>
        </div>
        <div v-else>
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>
import { Popup, Toast } from "vant";
import Vue from 'vue';
Vue.use(Popup);
import utils from '../utils'


export default {
    name: "App",
    data() {
        return {
            userId:'',
            token:'',
            red_beged:0,
            end:true,
            //收获机会
            prize_chance:0,
            //已收获次数
            exchanged:0,
            show_getChange:false,
            //可兑换
            myScore:0,
            myMeimei:0,
            scoreTime:0,
            meimeiTime:0,
            // 已兑换
            today_exchanged_time_score:0,
            today_exchanged_time_meimei:0,
            //提示弹窗
            alert_view:false,
            //奖品弹窗
            award_master:false,
            price_name:'',
            price:'',
            is_redBag:true,
            //跨年红包
            money_count:0,
            //任务按钮状态
            issue_isFinished:false,
            share_isFinished:false,
            invite_isFinished:false,

            //积分美枚兑换状态
            score:true,
            meimei:true,
            exchange_score:false,
            exchange_meimei:false,
            exchange_success_score:false,
            exchange_success_meimei:false,
            score_no:false,
            meimei_no:false,
            score_no_time:false,
            meimei_no_time:false,
            // 时间弹窗提示
            red_nostart:false,
            red_finished:false,
            red_rod:false,
            lucky_bag:false,
            stockings_finished:false,
            SHARE_DATA : {
                title: '五一劳动收麦子',
                desc: "五一在云美摄收麦子赢取现金红包！",
                imgUrl: `${utils.activityUrl}/image/suolvetu1_2x.png`,
                url: `${utils.activityUrl}/wuyiShare`
            },
            
        };
    },
     created(){
        document.title='五一活动'
        
    },
    watch:{
        
        end(val){
            if(val === false){
                console.log(this.$refs.prize_chance.innerText)
                this.$refs.prize_chance.innerText = ''
            }
        },
    },
    
    async mounted() {
        //获取userId和token
        this.userId = utils.getRequest().userId ? utils.getRequest().userId : "";
        this.token = utils.getRequest().token ? utils.getRequest().token : "";
       

        var {app_key, date, sign} = utils.getSign('v1/TwelveDay/activityStatistics')
        await this.$api.activityStatistics(app_key, date, sign,this.token,this.userId,1)

        var {app_key, date, sign} = utils.getSign('v1/TwelveDay/getActivityInfo')
        let {errNo,data,message} = await this.$api.getActivityInfo(app_key,date,sign,this.token,this.userId)
        if(errNo == 0){
            this.prize_chance = data.surplus_num
            this.end = data.end_time > new Date() ? false : true
        }
        

        window.SHARE_WEBHTML_MEISHE = ()=> {
            return this.SHARE_DATA;
        }
        window.APPHREF =  function (userId, token) {
            window.location.replace(utils.activityUrl+`/Index?userId=${userId}&token=${token}`);
        }
        this.share()
    },

    methods: {
        showgetChangeFloat: async function(){
            if(this.userId == '' || this.token == ''){
                window.location.href = "https://meisheapp.com?command=enterLogin";
                return false
            }

            this.show_getChange = true

            //统计点击赚取机会按钮点击量
            var {app_key, date, sign} = utils.getSign('v1/TwelveDay/activityStatistics')
            await this.$api.activityStatistics(app_key, date, sign,this.token,this.userId,2)

            //调用弹层接口
            var {app_key, date, sign} = utils.getSign('v1/TwelveDay/getExchangeList')
            let {errNo,data,message} = await this.$api.getChangeInfo(app_key,date,sign,this.token,this.userId)
            if(errNo == 0){
               this.myMeimei = data.coins
               this.myScore = data.credits

               for (let key in data.nums) {
                   if (data.nums.hasOwnProperty(key)) {
                       let element = data.nums[key];
                       if(element.type == 2){
                            this.today_exchanged_time_score = element.nums
                            this.scoreTime = element.surplus_nums
                        }else if(element.type == 3){
                            this.today_exchanged_time_meimei = element.nums
                            this.meimeiTime = element.surplus_nums
                        }else if(element.type == 6 && element.surplus_nums == 0){
                                this.issue_isFinished = true
                        }else if(element.type == 5 && element.surplus_nums == 0){
                                this.share_isFinished = true
                        }else if(element.type == 7 && element.surplus_nums == 0){
                                this.invite_isFinished = true
                        }
                   }
               }
            }

        },
        //关闭浮层
        close:async function(num){
            if(num === 1){
                
                let {app_key, date, sign} = utils.getSign('v1/TwelveDay/getActivityInfo')
                let {errNo,data,message} = await this.$api.getActivityInfo(app_key,date,sign,this.token,this.userId)
                if(errNo == 0){
                    this.prize_chance = data.surplus_num
                }
            }else if(num == 2){
                this.alert_view = false
            }else{
                this.show_getChange= false
                this.award_master = false
                this.exchange_success_score = false
                this.score_no_time = false
                this.score_no = false
                this.exchange_success_meimei = false
                this.meimei_no_time = false
                this.meimei_no = false
                this.meimei = true
                this.score = true
                this.exchange_meimei = false
                this.exchange_score = false
            }
            
        },
        //开启分享按钮
        share(){
            window.location.href = 'http://meisheapp.com?command=initShareInfo&url=' + this.SHARE_DATA.url + '&imgUrl=' + this.SHARE_DATA.imgUrl + '&title=' + this.SHARE_DATA.title + '&desc=' + this.SHARE_DATA.desc;
        },
        //直接开启分享弹层
        shareFloat(){
            window.location.href = 'http://meisheapp.com?command=shareWebHtml&url=' + this.SHARE_DATA.url + '&imgUrl=' + this.SHARE_DATA.imgUrl + '&title=' + this.SHARE_DATA.title + '&desc=' + this.SHARE_DATA.desc;
        },
        //轮播文字
        showMarquee: function () {
            this.animate = true;
            this.timer1 = setTimeout(() => {
                this.carouselArr.push(this.carouselArr[0]);
                this.carouselArr.shift();
                this.animate = false;
            }, 500);

        },
        //制作视频
        async makeVideo(){
            if(this.userId == '' || this.token == ''){
                window.location.href ="http://meisheapp.com?command=enterLogin";
            }else{
                utils.JOIN_ACTIVITY('', this.activityName, this.activityId, 2, 7, '')
            }
        },
        close_web(){
            utils.CLOSE_PAGE()
        },
        //奖励弹层
        async price_alert(num){
            if(this.userId == '' || this.token == ''){
                window.location.href = "https://meisheapp.com?command=enterLogin";
                return false
            }
            if(this.userId != '' && this.token != ''){
                //查询已经抽奖次数
                var {app_key, date, sign} = utils.getSign('v1/TwelveDay/drawCount')
                let result = await this.$api.drawCount(app_key,date,sign,this.token,this.userId)
                if(result.errNo === 0){
                    this.exchanged = result.data.count
                }
                if(this.exchanged < 20 && this.prize_chance >= 1){
                    let {app_key, date, sign} = utils.getSign('v1/TwelveDay/draw')
                    let {errNo, message, data} = await this.$api.getPriceInfo(app_key,date,sign,this.token,this.userId)
                    if(errNo == 0){
                        data.type === 1 ? this.is_redBag = true : this.is_redBag = false
                        this.price_name = data.name
                        this.price = data.reward_desc
                         //调奖励弹窗
                        this.award_master = true
                    }else{
                        Toast(message)
                        this.award_master = false
                    }
                   
                }else if(this.exchanged >= 20 ){
                    Toast('今天的收获机会已用完，请明天再来')
                }else if(this.prize_chance < 1){
                    Toast('您当前没有收获机会，点击右侧按钮【赚取机会】')
                }
            }
        },
        //兑换按钮
        exchange_btn(type){
            //1: 积分   2:美枚
                if(type === 1){
                    this.score = false
                    this.meimei = false
                    if(this.scoreTime > 0 && this.myScore >= 888){
                        this.exchange_score = true
                    }else if(this.myScore < 888){
                        this.score_no = true
                    }else if(this.scoreTime <= 0){
                        this.score_no_time = true
                    }
                    
                }else{
                    this.score = false
                    this.meimei = false
                    if(this.meimeiTime > 0 &&  this.myMeimei >= 8){
                        this.exchange_meimei = true
                    }else if(this.myMeimei < 8){
                        this.meimei_no = true
                    }else if(this.meimeiTime <= 0){
                        this.meimei_no_time = true
                    }
                }
        },
        //我要兑换
        async to_change(type){
            this.exchange_meimei = false
            this.exchange_score = false
            if(type === 1){
                if(this.scoreTime >= 1 && this.myScore >= 888){
                    // 增加一次抽奖机会（积分兑换）
                    let {app_key, date, sign} = utils.getSign('v1/TwelveDay/exchangeNum')
                    let {errNo,data,message} = await this.$api.addExangeTime(app_key,date,sign,this.token,this.userId,2,888,1)
                    // let {errNo,data,message} = await this.$api.addExangeTime(this.userId,2,888,1)
                    if(errNo == 0){
                        this.exchange_success_score = true
                    }else if(errNo == 500){
                        
                    }
                }else if(this.scoreTime < 1){
                    this.score_no_time = true
                }else if(this.scoreTime >= 1 && this.myScore < 888){
                    this.score_no = true
                }
            }else if(type === 2){
                if(this.meimeiTime >= 1 && this.myMeimei >= 8){
                    // 增加一次抽奖机会（美枚兑换）
                    let {app_key, date, sign} = utils.getSign('v1/TwelveDay/exchangeNum')
                    let {errNo,data,message} = await this.$api.addExangeTime(app_key,date,sign,this.token,this.userId,3,8,1)
                    this.exchange_success_meimei = true
                }else if(this.meimeiTime < 1){
                    this.meimei_no_time = true
                }else if(this.myMeimei < 8){
                    this.meimei_no = true
                }
            }
        },
        //关闭弹层，重新获取主页面最新数据
        async close_popue(){
            let {app_key, date, sign} = utils.getSign('v1/TwelveDay/getActivityInfo')
            let {errNo,data,message} = await this.$api.getActivityInfo(app_key,date,sign,this.token,this.userId)
            this.prize_chance = data.surplus_num
        },
        async goFloatIndex(){
           //调用弹层接口
            var {app_key, date, sign} = utils.getSign('v1/TwelveDay/getExchangeList')
            let {errNo,data,message} = await this.$api.getChangeInfo(app_key,date,sign,this.token,this.userId)
            if(errNo == 0){
               this.myMeimei = data.coins
               this.myScore = data.credits

               for (let key in data.nums) {
                   if (data.nums.hasOwnProperty(key)) {
                       let element = data.nums[key];
                       if(element.type == 2){
                            this.today_exchanged_time_score = element.nums
                            this.scoreTime = element.surplus_nums
                        }else if(element.type == 3){
                            this.today_exchanged_time_meimei = element.nums
                            this.meimeiTime = element.surplus_nums
                        }
                   }
               }
           
            }
            this.exchange_success_score = false
            this.exchange_success_meimei = false
            this.score = true
            this.meimei = true
        },  
        //去提现
        goMoney(){
            window.location.href =`${utils.webAPPUrl}?userId=${this.userId}&token=${this.token}`
        },
        //去我的奖励
        goMyAward(){
            if(this.userId == '' || this.token == ''){
                window.location.href = "https://meisheapp.com?command=enterLogin";
                return false
            }
            this.$router.push(`/wuyiMyAward?userId=${this.userId}&token=${this.token}`)
        }
        
       
    },
    beforeDestroy(){
        clearTimeout(this.timer1)
    }
};
</script>

<style scoped >
img{
    display: block;
}
.page{
    position: relative;
    background: url('../static/image/indeximg/Shape 1076 copy@2x.png') no-repeat;
    background-size: 100%;
    height: 1053px;
    overflow: hidden;
}

.progress_bar{
    width: 369px;
    height: 102px;
    margin-left: 6px;
}
.title_img{
    width: 347px;
    height: 105px;
    margin: 9px auto;
}
.carousel_text{
    position: absolute;
    top: 192px;
    left: 80px;
    width: 220px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
}
.carousel_item{
    display: flex;
    font-size: 10px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F5383C;
    justify-content: center;
}
.user_getInfo{
    margin: 0 7px 0 3px;
}
.rule{
    background: url('../static/image/indeximg/Group 20.png') no-repeat;
    background-size: 100%;
    width: 66px;
    height: 21px;
    position: absolute;
    top: 225px;
    right: -7px;
   
}
.Up{
    overflow: hidden;
}
.Main{
    display: flex;
    margin-top: 178px;
    justify-content: center;
}
.left_inner{
    margin-top: 83px;
}
.left_img{
    width: 77px;
    height: 76px;
}
.big_img{
    width: 207px;
    height: 206px;
    /* animation: rotate 1.5s infinite linear; */
}
.big_end_info{
    width: 207px;
    height: 206px;
}
.big_img_end{
    width: 100%;
    height: 100%;
}
.big_end_text{
    position: absolute;
    top: 422px;
    left: 153px;
    width: 70px;
    height: 24px;
    background: #FCF5DD;
    border: 1px solid #F9D4AE;
    border-radius: 8px;
    font-size: 13px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #E13431;
    line-height: 24px;
    text-align: center;
}
@keyframes rotate {
    
    0%{
        transform: rotate(0deg); 
    }
    25%{
        transform: rotate(10deg);
    }
    75%{
        transform: rotate(-10deg);
    }
    100%{
        transform: rotate(0);
    }
}
.right_inner{
    margin-top: 26px;
}
.right_img{
    width: 77px;
    height: 76px;
}
.open_status{
    width: 52px;
    height: 21px;
    margin: -5px auto 0;
}
.middle{
    position: relative;
    width: 100%;
    height: 96px;
    margin-top: 46px;
    padding: 7px 0;
    box-sizing: border-box;
}
.prize_chance{
    width: 75px;
    height: 12px;
    margin: 0 auto;
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    color: #F6D9B5;
    line-height: 15px;
    text-align: center;
}
.btn_group{
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myAward{
    width: 88px;
    height: 44px;
}
.openBtn{
    width: 163px;
    height: 57px;
    /* animation: scale 1.5s infinite linear; */
}
.end_btn{
    width: 163px;
    height: 57px;
}
@keyframes scale {
    0%{
        transform: scale(1);  /*开始为原始大小*/
    }
    
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}
.getChange{
    width: 88px;
    height: 44px;
}
.button{
    margin: 0 11px;
}
.openBtnText{
    position: absolute;
    top: 27px;
    left: 108px;
    width: 163px;
    height: 57px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 57px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.19);
    text-align: center;
    /* animation: scale 1.5s infinite linear; */
}
.Down{
    background: url('../static/image/indeximg/Layer 43@2x.png') no-repeat;
    height: 423px;
    width: 348px;
    margin: 50px auto;
    background-size: 100%;
}
.time_shaft{
    position: absolute;
    top: 64px;
    left: 64px;
    width: 273px;
    height: 5px;
    background: #F8A64C;
    box-shadow: 0px 2px 2px 0px rgba(248, 136, 14, 0.75);
    border-radius: 3px;
}
.timing1{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    left: 85px;
}
.timing2{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    left: 190px;
}
.timing3{
    position: absolute;
    top: 59px;
    width: 15px;
    height: 15px;
    right: 61px;
}
.timingD{
    position: absolute;
    top: 57px;
    width: 23px;
    height: 23px;
}
.float{
    height: 556px;
    width: 100%;
}
.float_main{
    background: url('../static/image/float/Group 31.png') no-repeat;
    background-size: 100%;
    height: 632px;
    position: relative;
}
.exchangeTitle{
    width: 117px;
    height: 19px;
    position: absolute;
    top: 15px;
    left: 133px;
}
.validityDesc{
    width: 153px;
    height: 12px;
    position: absolute;
    top: 75px;
    left: 114px;
}
.exchange_wrap{
    background: url('../static/image/float/Layer 26.png') no-repeat;
    background-size: 100%;
    height: 194px;
    position: absolute;
    top: 88px;
    width: 100%;
    display: flex;
    padding: 30px 41px 42px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}
.score{
    text-align: center;
}
.meimei{
    text-align: center;
}
.score_icon,.exchange_score_icon{
    width: 41px;
    height: 41px;
    margin: 0 auto 12px;
}
.exchange_score,.exchange_meimei,.exchange_success_score,.exchange_success_meimei,.meimei_no,.score_no,.score_no_time,.meimei_no_time{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.today_exchanged_score,.today_residue_score,.today_exchanged_meimei,.today_residue_meimei,.meimei_no_desc,.score_no_desc{
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
}
.success_exchanged_desc{
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
}
.exchange_sure_score,.know,.exchange_sure_meimei{
    width: 117px;
    height: 29px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    margin-top: 7px;
}
.meimei_icon,.exchange_meimei_icon{
    width: 48px;
    height: 35px;
    margin: 6px auto 12px;
}
.my_score,.exchange_time,.my_meimei{
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F84D37;
    margin-bottom: 3px;
}
.exchange_btn{
    width: 117px;
    height: 27px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    margin: 0 auto;
}
.exchange_title{
    width: 156px;
    height: 18px;
    font-size: 19px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #F6F2BD;
    line-height: 23px;
    text-align: center;
    position: absolute;
    top: 286px;
    left: 111px;
}
.mession_group{
    position: absolute;
    top: 317px;
    left: 17px;
}
.issue,.share,.invite{
    width: 343px;
    height: 86px;
    background: #FED8AB;
    border-radius: 10px;
    padding: 19px 8px 17px 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.issue_info,.share_info,.invite_info{
    margin: 0 12px 0 19px;
}
.issue_title,.share_title,.invite_title{
    width: 182px;
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #CD2400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
}
.issue_desc1,.issue_desc2,.share_desc1,.share_desc2,.invite_desc1,.invite_desc2{
    /* width: 157px; */
    font-size: 13px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #D81400;
    line-height: 16px;
    text-align: start;
}
.issue_icon,.share_icon,.invite_icon{
    width: 30px;
    height: 30px;
}
.issue_btn,.share_btn,.invite_btn{
    width: 77px;
    height: 29px;
    background: linear-gradient(270deg, #FA511C, #FD763C);
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 31px;  
    text-align: center;
}
.finished{
    width: 77px;
    height: 29px;
    background: #FFC3C3;
    border-radius: 14px;
    font-size: 15px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #5C66A5;
    line-height: 29px;
    text-align: center;
}
.close{
    width: 24px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
}
.alert_master,.award_master{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
}
.alert_main{
    position: absolute;
    top: 130px;
    left: 35px;
    width: 300px;
    height: 343px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 23px ;
    box-sizing: border-box;
}
.award_main,.award_redBag{
    background: url('../static/image/alertimg/telecom_red_envelop.png') no-repeat;
    background-size: 100%;
    position: absolute;
    top: 107px;
    left: 52px;
    width: 270px;
    height: 325px;
}
.close_alert,.award_close{
    width: 32px;
    height: 32px;
    position: absolute;
    top: 489px;
    left: 169px;
}
.award_close{
    top: 458px;
    left: 170px;
}
.line{
    width: 189px;
    height: 14px;
    background: rgba(245, 87, 75, 0.3);
    margin: 58px auto 0;
}
.alert_title{
    width: 85px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202023;
    line-height: 22px;
    margin: -26px auto 0;
    text-align: center;
}
.red_time,.red_finishedTime,.lucky_time{
    width: 260px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F5574B;
    line-height: 22px;
    margin: 36px auto 0;
    text-align: center;
}
.red_finishedTime{
    margin: 73px auto 0;
}
.red_desc,.lucky_desc{
    width: 210px;
    height: 44px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F5574B;
    line-height: 22px;
    margin: 15px auto 33px;
    text-align: center;
}
.lucky_desc{
    width: 152px;
    margin-bottom: 73px;
}
.red_condition{
    width: 116px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555559;
    line-height: 17px;
    margin: 0 auto 23px;
}
.red_know,.lucky_know{
    width: 255px;
    height: 35px;
    background: #F5574B;
    border-radius: 18px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
}
.price_title,.price_name{
    width: 174px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F56857;
    line-height: 22px;
    margin: 31px auto 0;
    text-align: center;
}
.price_name{
    margin: 2px auto 0;
    text-align: center;
}
.price{
    width: 187px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F56857;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 22px auto 0;
    text-align: center;
}
.see_price,.goMoney{
    width: 202px;
    height: 41px;
    background: #FFDAA3;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F56857;
    line-height: 41px;
    margin: 65px auto 0;
    text-align: center;
}
.share_lucky{
    width: 201px;
    height: 39px;
    border-radius: 8px;
    border: 1px solid #FFDAA3;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FFDAA3;
    line-height: 39px;
    margin: 22px auto 0;
    text-align: center;
}
.award_redBag_title{
    width: 174px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F56857;
    line-height: 22px;
    margin: 41px auto 36px;
    text-align: center;
}
.money_count{
    width: 187px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #F56857;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 0 auto 97px;
    text-align: center;
}
.go_money{
    width: 202px;
    height: 41px;
    background: #FFDAA3;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #F56857;
    line-height: 41px;
    margin: 0 auto;
    text-align: center;
}
.marquee_top {
    transition: all 0.5s;
    margin-top: -19px;
}
.sandian{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
